import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { BUTTON_LABELS } from '../../cms';
import { useAppDispatch } from '../../rtk/hooks';
import { useGetPreferencesQuery } from '../../rtk/api/adminApi';
import { useUpdateMyInterestsMutation } from '../../rtk/api/employeeApi';
import { updateMyPreferences } from './actions';

type UserPreferencesData = {
  interests?: {
    key: number;
    name: string;
  }[];
};

type UserPreferencesProps = {
  preferences?: UserPreferencesData;
  onClose: () => void;
};

export default function UserPreferences({
  preferences,
  onClose,
}: UserPreferencesProps) {
  const compId = 'user-preferences';

  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isError } = useGetPreferencesQuery();
  const [updateMyInterests] = useUpdateMyInterestsMutation();

  const [selectedIds, setSelectedIds] = useState<number[]>(
    preferences?.interests?.map((interest) => interest.key) ?? [],
  );

  useEffect(() => {
    if (isError) onClose();
  }, [isError]);

  const handleCheckboxChange = (checkedId: number, checked: boolean) => {
    if (checked) {
      setSelectedIds([...selectedIds, checkedId]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== checkedId));
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await updateMyInterests(selectedIds);

      if ('data' in response) {
        dispatch(updateMyPreferences(response.data));
      }
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <>
      <Stack
        direction="column"
        gap="1.5rem"
        sx={{
          padding: '2rem',
        }}
        className={compId}
      >
        <Typography>Set up Your Areas of Interests</Typography>
        <Divider />

        <Grid
          container
          spacing="1.5rem"
          sx={{
            maxHeight: '65vh',
            width: 'auto',
            maxWidth: isScreenMd ? 'auto' : '55vw',
            overflowX: 'auto',
          }}
        >
          {data &&
            data.map(
              (item) =>
                item.preferences?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    key={item.category.id}
                  >
                    <Stack
                      direction="column"
                      gap="1rem"
                      sx={{ paddingTop: '0' }}
                    >
                      <Typography>
                        <strong className={`${compId}-preference-category`}>
                          {item.category.name}
                        </strong>
                      </Typography>

                      <Stack direction="column" gap="0.1rem">
                        {item.preferences.map((preference) => (
                          <FormControlLabel
                            key={preference.id}
                            control={
                              <Checkbox
                                color="default"
                                className={`${compId}-preference-checkbox`}
                                checked={selectedIds.includes(preference.id)}
                                onChange={(_, checked) =>
                                  handleCheckboxChange(preference.id, checked)
                                }
                                value={preference.id}
                                name="preference"
                              />
                            }
                            label={preference.name}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Grid>
                ),
            )}
        </Grid>

        <Typography fontSize="small">
          * If you would like to see additional Categories for your preferences,
          please send a message to{' '}
          <a href="mailto:liliya.zlatanova@ict.eu">Liliya Zlatanova</a>.
        </Typography>
        <Divider />

        <Stack flexDirection="row" gap="1rem" justifyContent="flex-end">
          <Button
            variant="outlined"
            className={`${compId}-cancel-button`}
            onClick={onClose}
          >
            {BUTTON_LABELS.CANCEL}
          </Button>

          <LoadingButton
            variant="contained"
            className={`${compId}-submit-button`}
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={selectedIds.length === 0}
          >
            {BUTTON_LABELS.SAVE_BUTTON}
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
}
