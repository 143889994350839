import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarToday } from '@mui/icons-material';
import { DateTimePickerProps } from '@mui/lab';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { DATE_FORMATS } from '../../../cms/index';
import { CustomTextField } from '../index';

const CustomDateTimePicker = ({
  required,
  error,
  helperText,
  value,
  minTime,
  maxTime,
  minDate,
  maxDate,
  format = DATE_FORMATS.NORMAL,
  views = ['year', 'month', 'day'],
  className,
  clearable = false,
  ...rest
}: DateTimePickerProps<Dayjs>) => {
  return (
    <DateTimePicker
      ampm={false}
      {...rest}
      className={clsx('custom-date-picker', className)}
      sx={{ width: '50%' }}
      slots={{
        textField: CustomTextField,
        openPickerIcon: CalendarToday,
      }}
      slotProps={{
        field: { clearable: clearable },
        textField: {
          required,
          error,
          helperText,
          color: 'secondary',
          inputProps: {
            className,
          },
        },
        inputAdornment: {
          position: 'start',
        },
        actionBar: {
          actions: [],
        },
        openPickerButton: {
          sx: { fontSize: 16 },
        },
        openPickerIcon: {
          fontSize: 'inherit',
        },
      }}
      views={views}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
      }}
      format={format}
      value={value ? dayjs(value) : null}
      minTime={minTime ? dayjs(minTime) : undefined}
      maxTime={maxTime ? dayjs(maxTime) : undefined}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
    />
  );
};

export default CustomDateTimePicker;
