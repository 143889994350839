import { useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DATE_FORMATS,
  EVENT_APPLICATIONS_LABELS,
  EXPORTS,
  TRAINING_APPLICATIONS_LABELS,
} from '../../../../cms';
import TrainingApplicationTableRow from './TrainingApplicationsTableRow';
import './TrainingApplicationsTab.scss';
import { SearchField } from '../../../../common/components';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import {
  convertToCsv,
  downloadFile,
  formatTrainingApplicationsForExport,
} from '../../../../cms/Utils';
import { useRemoveUserFromTrainingMutation } from '../../../../rtk/api/adminApi';
import { useAppDispatch, useAppSelector } from '../../../../rtk/hooks';
import {
  incrementTrainingApplicationsPageNumber,
  resetTrainingApplicationsPagination,
  setTrainingApplicationsFromDate,
  setTrainingApplicationsSearch,
  setTrainingApplicationsToDate,
} from '../../../../rtk/features/trainingApplicationsPaginationSlice';
import {
  ApplicationsResponse,
  useGetApplicationsQuery,
  useLazyGetApplicationsForExportQuery,
} from '../../../../rtk/api/applicationApi';
import dayjs from 'dayjs';
import DateRange from '../../../../components/DateRange/DateRange';

export default function TrainingApplicationsTab() {
  const dispatch = useAppDispatch();

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const { search, pageNumber, pageSize, refetch } = useAppSelector(
    (state) => state.trainingApplicationsPagination,
  );
  const debouncedSearchText = useDebounce(search.trim().toLowerCase());
  const [removeUserFromTraining] = useRemoveUserFromTrainingMutation();
  const { data } = useGetApplicationsQuery(
    {
      pageNumber,
      pageSize,
      searchText: debouncedSearchText,
      type: 'Training',
      refetch,
      dateFrom,
      dateTo,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const trainingApplications = data ? data.values : [];
  const [getTrainingApplicationsForExport, { isLoading }] =
    useLazyGetApplicationsForExportQuery();

  const hasMore = pageNumber * pageSize === trainingApplications.length;

  useEffect(() => {
    return () => {
      dispatch(resetTrainingApplicationsPagination());
    };
  }, []);

  const handleOnSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTrainingApplicationsSearch(e.target.value));
  };

  const fetchMoreData = () => {
    dispatch(incrementTrainingApplicationsPageNumber());
  };

  const handleRemoveUser = (trainingId: number, userId: number) => {
    removeUserFromTraining({
      trainingId,
      userId,
    });
  };

  const onExport = () => {
    getTrainingApplicationsForExport({
      pageSize: null,
      pageNumber: null,
      searchText: '',
      type: 'Training',
      refetch: 0,
      dateFrom: null,
      dateTo: null,
    })
      .unwrap()
      .then(downloadCsvFile)
      .catch(() => {});
  };

  const downloadCsvFile = (
    trainingApplicationsForExport: ApplicationsResponse,
  ) => {
    const foratedTrainingApplicationsForExport =
      formatTrainingApplicationsForExport(trainingApplicationsForExport.values);
    const csvData = convertToCsv(foratedTrainingApplicationsForExport);
    downloadFile(csvData, 'training-applications.csv', 'text/csv');
  };

  const onFromDateChange = (newFromDate: string) => {
    if (newFromDate !== undefined) {
      const date = dayjs(newFromDate, DATE_FORMATS.SIMPLE_DASH)
        .startOf('day')
        .utcOffset('+0200')
        .toDate();
      setDateFrom(date);
      dispatch(setTrainingApplicationsFromDate(date));
    } else {
      setDateFrom(null);
      dispatch(setTrainingApplicationsFromDate(undefined));
    }
  };

  const onToDateChange = (newToDate: string) => {
    if (newToDate !== undefined) {
      const date = dayjs(newToDate, DATE_FORMATS.SIMPLE_DASH)
        .endOf('day')
        .utcOffset('+0200')
        .toDate();
      setDateTo(date);
      dispatch(setTrainingApplicationsToDate(date));
    } else {
      setDateTo(null);
      dispatch(setTrainingApplicationsToDate(undefined));
    }
  };

  return (
    <Stack spacing="2rem" className="training-applications">
      <Stack
        direction="row"
        spacing="1rem"
        flexWrap="wrap"
        alignItems="flex-end"
      >
        <SearchField
          placeholder="Search training by title..."
          value={search}
          onChange={handleOnSearchTextChange}
          sx={{
            width: { xs: '100%', sm: 300 },
          }}
          className="training-applications-search"
        />
        <DateRange
          onFromDateChange={onFromDateChange}
          onToDateChange={onToDateChange}
          clearable={true}
        />
        <LoadingButton
          variant="contained"
          onClick={onExport}
          className="training-applications-export-btn"
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
          startIcon={<FileDownloadIcon />}
          loading={isLoading}
          loadingPosition="start"
        >
          {EXPORTS.EXPORT_TO_CSV}
        </LoadingButton>
      </Stack>
      <TableContainer component={Paper}>
        <InfiniteScroll
          dataLength={trainingApplications?.length ?? 0}
          next={fetchMoreData}
          hasMore={hasMore!}
          loader=""
          scrollableTarget="scrollableDiv"
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.TITLE}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.USER}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.EMAIL}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.MANAGER}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.SUBMITTED}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.TYPE}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.COST}
                </TableCell>
                <TableCell className="column" align="left">
                  {TRAINING_APPLICATIONS_LABELS.STATUS}
                </TableCell>
                <TableCell className="column" align="left">
                  {EVENT_APPLICATIONS_LABELS.REMOVE_LABEL}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainingApplications.map((row) => (
                <TrainingApplicationTableRow
                  key={row.id}
                  row={row}
                  removeUser={handleRemoveUser}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
    </Stack>
  );
}
