import { isEqual, unionBy } from 'lodash';
import {
  APPLICATIONS_URL,
  APPROVE_APPLICATION_URL,
  MY_EMPLOYEES_APPLICATIONS,
  PERSONAL_APPLICATIONS_URL,
  REJECT_APPLICATION_URL,
} from '../../api/trainingsPortalBackend/config';
import {
  ApplicationStatuses,
  ApplicationType,
  MyTrainingsAndEventsTypes,
} from '../../cms/enums';
import { Application } from '../../cms/types/applcationTypes';
import { api } from './api';

export type PersonalApplication = {
  id: number;
  entryId: number;
  title: string;
  pathId: number | null;
  comment: string;
  statusDate: Date;
  type: MyTrainingsAndEventsTypes;
  status: ApplicationStatuses;
  isEntityArchived: boolean;
  isEntityExpired: boolean;
};

export type PersonalApplicationParams = {
  userId: number;
  search: string;
  type: string;
  status: string;
};

export type UpdateApplicationsStatusModel = {
  applicationType: ApplicationType;
  applicationStatus: number;
};

export type UpdateApplicationsStatusRequest = {
  id: number;
  model: UpdateApplicationsStatusModel;
};

export type ReviewApplicationRequest = {
  comment: string;
  applicationId: number;
  applicationType: ApplicationType;
};

export type GetApplicationsParams = {
  pageSize: number | null;
  pageNumber: number | null;
  searchText: string;
  type: string;
  refetch: number;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export type MyEmployeesApplicationParams = {
  pageSize: number;
  pageNumber: number;
  searchText: string;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export type ApplicationsResponse = {
  totalCount: number;
  values: Application[];
};

export const applicationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApplications: build.query<ApplicationsResponse, GetApplicationsParams>({
      query: (params) => ({ url: APPLICATIONS_URL, params }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg.pageNumber === 1) return responseData;

        return {
          totalCount: responseData.totalCount,
          // Combine both arrays and skip duplicates
          values: unionBy(currentCacheData.values, responseData.values, 'id'),
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
      providesTags: ['Application'],
    }),
    getPersonalApplications: build.query<
      PersonalApplication[],
      PersonalApplicationParams
    >({
      query: (params) => ({ url: PERSONAL_APPLICATIONS_URL, params }),
      providesTags: ['Application'],
    }),
    updateApplicationStatus: build.mutation<
      any,
      UpdateApplicationsStatusRequest
    >({
      query: ({ id, model }) => ({
        url: `${APPLICATIONS_URL}/${id}/status`,
        method: 'PATCH',
        body: model,
      }),
      invalidatesTags: (result, error) =>
        error
          ? []
          : [
              'Training',
              'TrainingApplication',
              'Application',
              'Path',
              'Event',
              'EventApplication',
            ],
    }),
    approveApplication: build.mutation<any, ReviewApplicationRequest>({
      query: (model) => ({
        url: APPROVE_APPLICATION_URL,
        method: 'PATCH',
        body: model,
      }),
      invalidatesTags: ['MyTeamApplications'],
    }),
    rejectApplication: build.mutation<any, ReviewApplicationRequest>({
      query: (model) => ({
        url: REJECT_APPLICATION_URL,
        method: 'PATCH',
        body: model,
      }),
      invalidatesTags: ['MyTeamApplications'],
    }),
    getMyEmployeesApplications: build.query<
      ApplicationsResponse,
      MyEmployeesApplicationParams
    >({
      query: (params) => ({ url: MY_EMPLOYEES_APPLICATIONS, params }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData, { arg }) => {
        if (arg.pageNumber === 1) return responseData;

        return {
          totalCount: responseData.totalCount,
          // Combine both arrays and skip duplicates
          values: unionBy(currentCacheData.values, responseData.values, 'id'),
        };
      },
      providesTags: ['MyEmployeesApplications'],
      forceRefetch: ({ currentArg, previousArg }) =>
        isEqual(currentArg, previousArg),
    }),
    getApplicationsForExport: build.query<
      ApplicationsResponse,
      GetApplicationsParams
    >({
      query: (params) => ({ url: APPLICATIONS_URL, params }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetPersonalApplicationsQuery,
  useGetMyEmployeesApplicationsQuery,
  useUpdateApplicationStatusMutation,
  useApproveApplicationMutation,
  useRejectApplicationMutation,
  useLazyGetApplicationsForExportQuery,
} = applicationApi;
