import { useEffect, useState } from 'react';
import { Button, Rating } from '@mui/material';
import {
  BUTTON_LABELS,
  FAILED_REQUEST_MESSAGE,
  MODAL_CLOSE_BTNS,
  MODAL_VALUES,
  NOTIFICATION_LABELS,
  RATING_FORM_PLACEHOLDER,
  SCREEN_SIZES,
} from '../../cms/index';
import './RateTrainingForm.scss';
import { useRateTrainingMutation } from '../../rtk/api/trainingApi';
import useMediaQuery from '../../common/hooks/useMediaQuery';
import { useAppDispatch } from '../../rtk/hooks';
import { openModal } from '../../rtk/features/modalsSlice';
import { ModalTypes } from '../../cms/enums';

interface RateTrainingFormProps {
  trainingId: number;
  trainingTitle: string;
  currentRating?: number;
  onClose: () => void;
}

const RateTrainingForm = ({
  trainingId,
  trainingTitle,
  currentRating = 0,
  onClose,
}: RateTrainingFormProps) => {
  const dispatch = useAppDispatch();

  const [rateTraining, result] = useRateTrainingMutation();
  const { isLoading, isSuccess, isError } = result;

  const [comment, setComment] = useState('');
  const [rating, setRating] = useState<number>(currentRating);

  const isScreenSmall = useMediaQuery(SCREEN_SIZES.EXTRA_SMALL);

  const handleRateTraining = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    rateTraining({
      id: trainingId,
      comment,
      rating,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        openModal({
          modalType: ModalTypes.SUCCESS_MESSAGE,
          modalData: NOTIFICATION_LABELS.TRAINING_HAVE_BEEN_RATED,
          closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );

      onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(
        openModal({
          modalType: ModalTypes.ERROR_MESSAGE,
          modalData: FAILED_REQUEST_MESSAGE,
          timeout: MODAL_VALUES.MODAL_CLOSE_TIMEOUT,
        }),
      );

      onClose();
    }
  }, [isError]);

  return (
    <form
      onSubmit={handleRateTraining}
      className={`rate-training-form${isScreenSmall ? '-small' : ''}`}
    >
      <h5>Rate Training</h5>
      <hr />
      <p>We'd love to hear your thoughts on your completed trainings! You can leave ratings and comments anytime - even for already finished trainings. Your feedback will help us improve and bring you even better features.</p>
      <h6>{trainingTitle}</h6>

      <Rating
        name="controlled"
        value={rating}
        onChange={(event, newRatingValue) => {
          setRating(Number(newRatingValue));
        }}
        size="large"
      />

      <textarea
        className="portal-textarea"
        placeholder={RATING_FORM_PLACEHOLDER}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        disabled={isLoading}
      />

      <fieldset>
        <Button variant="outlined" className="skip-rate-btn" onClick={onClose}>
          {BUTTON_LABELS.SKIP.toUpperCase()}
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={rating === 0}
          className="form-submit-btn"
        >
          SUBMIT
        </Button>
      </fieldset>
    </form>
  );
};

export default RateTrainingForm;
