import { useState } from 'react';
import dayjs from 'dayjs';
import './TrainingDetailsHeader.scss';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Rating,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import pluralsightImage from '../../../common/images/logo-pluralsight-paths.png';
import udemyImage from '../../../common/images/logo-short-udemy.png';
import {
  COLORS,
  TRAINING_DETAIL_LABELS,
  MODAL_CLOSE_BTNS,
  BUTTON_LABELS,
} from '../../../cms';
import ChangeTrainingStatusButtons from '../ChangeTrainingStatusButtons/ChangeTrainingStatusButtons';
import { useAppDispatch } from '../../../rtk/hooks';
import { closeModal, openModal } from '../../../rtk/features/modalsSlice';
import { useDeleteTrainingMutation } from '../../../rtk/api/trainingApi';
import { retrieveExternalProvider } from '../../../cms/Utils';
import {
  ApplicationStatuses,
  ExternalProviders,
  ModalTypes,
} from '../../../cms/enums';
import { TrainingResponse } from '../../../cms/types/trainingTypes';

type TrainingDetailsHeaderProps = {
  training: TrainingResponse;
  isAdmin: boolean;
};

const options = [
  {
    name: TRAINING_DETAIL_LABELS.EDIT_TRAINING,
    className: 'edit-training',
    icon: <EditIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
  {
    name: TRAINING_DETAIL_LABELS.DUPLICATE_TRAINING,
    className: 'duplicate-training',
    icon: <ContentCopyIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
  {
    name: TRAINING_DETAIL_LABELS.DELETE_TRAINING,
    className: 'delete-training',
    icon: <DeleteIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
  {
    name: TRAINING_DETAIL_LABELS.RATE_TRAINING,
    className: 'rate-training',
    icon: <StarOutlineIcon sx={{ fontSize: '1.25rem', color: COLORS.TEXT }} />,
  },
];

const TrainingDetailsHeader = ({
  training,
  isAdmin,
}: TrainingDetailsHeaderProps) => {
  const dispatch = useAppDispatch();
  const [deleteTraining] = useDeleteTrainingMutation();
  const trainingHasEnded = dayjs().isAfter(dayjs(training.endDate), 'date');
  const trainingCompleted =
    training.applicationStatus === ApplicationStatuses.COMPLETED;
  const externalProvider = retrieveExternalProvider(training.url);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const externalLogo = externalProvider && (
    <Stack flexDirection="row" alignItems="center" gap="1rem">
      <img
        className="training-details-header-image"
        alt=""
        src={
          externalProvider === ExternalProviders.PLURALSIGHT
            ? pluralsightImage
            : udemyImage
        }
      />
      <p className="training-details-header-text">
        {externalProvider === ExternalProviders.PLURALSIGHT
          ? TRAINING_DETAIL_LABELS.PLURALSIGHT
          : TRAINING_DETAIL_LABELS.UDEMY}
      </p>
    </Stack>
  );

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteSpecifiedTraining = () => {
    deleteTraining(training!.id)
      .then(() => {
        dispatch(closeModal(ModalTypes.CONFIRM_ACTION));
      })
      .catch();
  };

  const handleShowEdit = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.TRAINING_FORM,
        modalData: {
          trainingData: training,
          type: 'Edit',
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleShowDuplicate = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.TRAINING_FORM,
        modalData: {
          trainingData: {
            ...training,
            title: `${training.title} (Duplicate)`,
          },
          type: 'Duplicate',
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleShowDelete = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.CONFIRM_ACTION,
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
        modalData: {
          text: TRAINING_DETAIL_LABELS.DELETE_CONFIRMATION_TEXT,
          btnText: BUTTON_LABELS.DELETE_BUTTON,
          onConfirm: deleteSpecifiedTraining,
        },
      }),
    );
  };

  const handleShowRate = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.RATE_TRAINING_FORM,
        modalData: {
          trainingId: training.id,
          trainingTitle: training.title,
          currentRating: training.rating,
        },
        closeBtn: MODAL_CLOSE_BTNS.OUTSIDE,
      }),
    );
  };

  const handleMenuItemClick = (menuItem: string) => {
    switch (menuItem) {
      case TRAINING_DETAIL_LABELS.EDIT_TRAINING:
        handleShowEdit();
        break;
      case TRAINING_DETAIL_LABELS.DUPLICATE_TRAINING:
        handleShowDuplicate();
        break;
      case TRAINING_DETAIL_LABELS.DELETE_TRAINING:
        handleShowDelete();
        break;
      case TRAINING_DETAIL_LABELS.RATE_TRAINING:
        handleShowRate();
        break;
      default:
        break;
    }
    handleCloseMenu();
  };

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap="2rem"
        flexWrap="wrap"
        className="training-details-header"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        {externalLogo && (
          <>
            {externalLogo}
            <Divider orientation="vertical" flexItem />
          </>
        )}

        <Stack direction="column" className="training-details-header-content">
          <Typography noWrap className="training-details-title">
            {training?.title}
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap="1rem">
            <Stack direction="row" alignItems="center" gap="0.2rem">
              <Rating
                name="read-only"
                value={training.rating}
                precision={0.5}
                size="small"
                readOnly
                sx={{
                  alignItems: 'flex-end',
                }}
              />
              <Typography className="rating-value">
                {training.rating}
              </Typography>
            </Stack>
            <Typography className="training-details-location">
              {training?.location} {TRAINING_DETAIL_LABELS.LOCATION}
            </Typography>
            {training?.createdBy && (
              <Typography className="training-details-creator">
                {TRAINING_DETAIL_LABELS.CREATED_BY}
                &nbsp;
                {training?.createdBy}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap="1rem"
          flexWrap="wrap"
          className="training-details-actions"
          alignItems="center"
        >
          <ChangeTrainingStatusButtons training={training} />
          {isAdmin || trainingCompleted ? (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpenMenu}
              className="options-menu-btn"
            >
              <MoreVertIcon color="primary" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
                disablePadding: true,
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
            >
              {options.map(({ name, icon, className }, index) => {
               if (!isAdmin && (name === TRAINING_DETAIL_LABELS.EDIT_TRAINING || name === TRAINING_DETAIL_LABELS.DELETE_TRAINING || name === TRAINING_DETAIL_LABELS.DUPLICATE_TRAINING))
                return null;
               if (trainingHasEnded && name === TRAINING_DETAIL_LABELS.EDIT_TRAINING)
                return null;
               if (!trainingCompleted && name === TRAINING_DETAIL_LABELS.RATE_TRAINING)
                return null;

                return (
                  <MenuItem
                    className={className}
                    key={name}
                    onClick={() => handleMenuItemClick(name)}
                    divider={index < options.length - 1}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontSize: 14 }}>
                      {name}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        ) : null}
        </Stack>
      </Stack>
    </>
  );
};

export default TrainingDetailsHeader;
