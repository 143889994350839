import { ARCHIVE_EMPLOYEE_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/ArchiveEmployee/types';
import { DEACTIVATE_EMPLOYEE_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/DeactiveateEmployee/types';
import {
  ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_SUCCESS,
  ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_ERROR,
} from '../components/MyGroup/MyTeam/MyEmployees/AddEmployeeModal/types';
import {
  REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_SUCCESS,
  REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_ERROR,
} from '../components/MyGroup/MyTeam/MyEmployees/Employees/types';
import { GET_ALL_DEPARTMENTS_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/GetAllDepartments/types';
import { GET_ALL_MANAGERS_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/GetAllManagers/types';
import { GET_ALL_PROFESSIONS_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/GetAllProfessions/types';
import { EDIT_EMPLOYEE_REQUEST_SUCCESS } from '../pages/AdministrationPage/tabs/EmployeeManagementTab/EmployeesTable/EditEmployee/types';
import {
  GET_ALL_EMPLOYEES_REQUEST_SUCCESS,
  GET_SINGLE_EMPLOYEE_REQUEST_SUCCESS,
} from '../components/SearchEmployee/types';
import { UPDATE_MY_PREFERENCES_SUCCESS } from '../components/UserPreferences/types';

export const initialState = {
  allEmployees: [],
  allManagers: [],
  allProfessions: [],
  allDepartments: [],
  editSuccess: false,
  archiveSuccess: false,
  deactivateSuccess: false,
  deactivatedEmployeesIds: [],
  currentUserData: null,
  addEmployeeToMyTeamSuccess: false,
  addEmployeeToMyTeamErrorMessage: null,
  removeEmployeeFromMyTeamSuccess: false,
  removeEmployeeFromMyTeamErrorMessage: null,
};

function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EMPLOYEES_REQUEST_SUCCESS:
      return {
        ...state,
        allEmployees: action.payload.allEmployees,
        editSuccess: false,
        archiveSuccess: false,
        deactivateSuccess: false,
        deactivatedEmployeesIds: [],
      };
    case GET_ALL_MANAGERS_REQUEST_SUCCESS:
      return {
        ...state,
        allManagers: action.payload.allManagers,
        archiveSuccess: false,
        deactivateSuccess: false,
        deactivatedEmployeesIds: [],
      };
    case GET_ALL_PROFESSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        allProfessions: action.payload.allProfessions,
        archiveSuccess: false,
        deactivateSuccess: false,
        deactivatedEmployeesIds: [],
      };
    case GET_ALL_DEPARTMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        allDepartments: action.payload.allDepartments,
        archiveSuccess: false,
        deactivateSuccess: false,
        deactivatedEmployeesIds: [],
      };
    case EDIT_EMPLOYEE_REQUEST_SUCCESS:
      return {
        ...state,
        editSuccess: action.payload.isSuccessfull,
      };
    case ARCHIVE_EMPLOYEE_REQUEST_SUCCESS:
      return {
        ...state,
        archiveSuccess: action.payload.isSuccessful,
      };
    case DEACTIVATE_EMPLOYEE_REQUEST_SUCCESS:
      return {
        ...state,
        deactivateSuccess: action.payload.isSuccessful,
        deactivatedEmployeesIds: action.payload.deactivatedEmployeeIds,
      };
    case GET_SINGLE_EMPLOYEE_REQUEST_SUCCESS:
      return {
        ...state,
        currentUserData: action.payload.employeeData,
      };
    case ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_SUCCESS:
      return {
        ...state,
        addEmployeeToMyTeamSuccess: action.payload.isSuccessful,
      };
    case ADD_EMPLOYEE_TO_MY_TEAM_REQUEST_ERROR:
      return {
        ...state,
        addEmployeeToMyTeamErrorMessage: action.payload,
      };
    case REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_SUCCESS:
      return {
        ...state,
        removeEmployeeFromMyTeamSuccess: action.payload.isSuccessful,
      };
    case REMOVE_EMPLOYEE_FROM_MY_TEAM_REQUEST_ERROR:
      return {
        ...state,
        removeEmployeeFromMyTeamErrorMessage: action.payload,
      };
    case UPDATE_MY_PREFERENCES_SUCCESS:
      return {
        ...state,
        currentUserData: {
          ...state.currentUserData,
          preferences: action.payload,
        },
      };
    default:
      return {
        ...state,
        editSuccess: false,
        archiveSuccess: false,
        deactivateSuccess: false,
        deactivatedEmployeesIds: [],
        addEmployeeToMyTeamSuccess: false,
        addEmployeeToMyTeamErrorMessage: null,
        removeEmployeeFromMyTeamSuccess: false,
        removeEmployeeFromMyTeamErrorMessage: null,
      };
  }
}

export default employeesReducer;
