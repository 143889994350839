import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { adminApi } from '../api/adminApi';

type EventApplicationsPaginationState = {
  pageNumber: number;
  pageSize: number;
  search: string;
  refetch: number;
};

const initialState: EventApplicationsPaginationState = {
  pageNumber: 1,
  pageSize: 20,
  search: '',
  refetch: 0,
};

const eventApplicationsPaginationSlice = createSlice({
  name: 'eventApplicationsPagination',
  initialState,
  reducers: {
    incrementEventApplicationsPageNumber: (state) => {
      state.pageNumber++;
    },
    setEventApplicationsSearch: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        pageNumber: 1,
        search: payload,
        type: 'Event',
      };
    },
    setEventApplicationsFromDate: (
      state: EventApplicationsPaginationState,
      { payload }: PayloadAction<Date | undefined>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        dateFrom: payload,
        type: 'Event',
      };
    },
    setEventApplicationsToDate: (
      state: EventApplicationsPaginationState,
      { payload }: PayloadAction<Date | undefined>,
    ) => {
      return {
        ...state,
        pageNumber: 1,
        dateTo: payload,
        type: 'Event',
      };
    },
    resetEventApplicationsPagination: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(adminApi.endpoints.removeUserFromEvent.matchFulfilled),
      (state) => {
        return {
          ...state,
          pageNumber: 1,
          refetch: state.refetch + 1,
        };
      },
    );
  },
});

export const {
  incrementEventApplicationsPageNumber,
  setEventApplicationsSearch,
  setEventApplicationsFromDate,
  setEventApplicationsToDate,
  resetEventApplicationsPagination,
} = eventApplicationsPaginationSlice.actions;
export default eventApplicationsPaginationSlice.reducer;
