import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ProfileSection.scss';
import InfoIcon from '@mui/icons-material/Info';
import { Avatar, IconButton, Typography, styled } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MailIcon from '@mui/icons-material/Mail';
import { getSingleEmployee } from '../../../components/SearchEmployee/actions';
import editUserPhotoIcon from '../../../common/images/edit-user-photo-icon.png';
import { SCREEN_SIZES, SUPPORT_EMAIL } from '../../../cms';
import { PROFILE_SECTION_MODALS } from '../../../cms/enums';
import defaultAvatar from '../../../common/images/avatar.png';
import useMediaQuery from '../../../common/hooks/useMediaQuery';
import { addSmallStyle } from '../../../cms/Utils';
import config from '../../../config';
import { BaseModal } from '../../../common/components';
import UserGuide from '../../../components/UserGuide/UserGuide';
import ProfilePhotoUpload from '../../../components/ProfilePhotoUpload/ProfilePhotoUpload';
import ContactForm from '../../../components/ContactSection/ContactForm/ContactForm';
import { useAppDispatch, useAppSelector } from '../../../rtk/hooks';
import UserPreferences from '../../../components/UserPreferences/UserPreferences';

const StyledIconButton = styled(IconButton)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  fontSize: '14px',
  fontFamily: 'OpenSans',
  color: '#424242',
  '& .MuiSvgIcon-root': {
    color: '#00AAC2',
    fontSize: '23px',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      color: '#0F596E',
    },
  },
}));

export default function ProfileSection() {
  const dispatch = useAppDispatch();
  const { currentUserData } = useAppSelector((state) => state.allEmployees);
  const { userId, photoUrl } = useAppSelector((state) => state.userSession);
  const [openedModal, setOpenedModal] = useState<string | null>(null);
  const {
    fullName,
    jobTitle,
    email,
    department,
    manager,
    managerJobTitle,
    managerPhotoUrl,
    preferences,
  } = currentUserData ?? {};
  const isScreenSmall = useMediaQuery(SCREEN_SIZES.SMALL);

  useEffect(() => {
    dispatch(getSingleEmployee(userId));
  }, []);

  const handleOnCloseModal = () => {
    setOpenedModal(null);
  };

  const openUploadProfilePhotoModal = () => {
    setOpenedModal(PROFILE_SECTION_MODALS.PROFILE_PHOTO_MODAL);
  };

  const handleOnSendEmail = () => {
    window.open(`mailto:${SUPPORT_EMAIL}`);
  };

  const handlePreferencesClick = () => {
    setOpenedModal(PROFILE_SECTION_MODALS.USER_PREFERENCES_MODAL);
  };

  useEffect(() => {
    if (
      currentUserData &&
      (!currentUserData.preferences ||
        !currentUserData.preferences.interests ||
        currentUserData.preferences.interests.length === 0)
    ) {
      setOpenedModal(PROFILE_SECTION_MODALS.USER_PREFERENCES_MODAL);
    }
  }, [currentUserData]);

  return (
    <div className="profile-section">
      {currentUserData && (
        <div className="profile-section-header">
          <div
            className="profile-section-avatar-container"
            onClick={openUploadProfilePhotoModal}
          >
            <Avatar
              className="profile-section-avatar"
              variant="circular"
              alt="Me"
              src={photoUrl ?? defaultAvatar}
            >
              {fullName?.substring(0, 1)}
            </Avatar>
            <img
              className="profile-section-edit-avatar-btn"
              alt="Edit Avatar"
              src={editUserPhotoIcon}
            />
          </div>
          <Typography
            noWrap
            className="profile-section-username"
            title={fullName}
          >
            {fullName}
          </Typography>
          <Typography noWrap className="profile-section-user-role">
            <Link
              to=""
              onClick={handlePreferencesClick}
              className="events-section-no-data-link"
            >
              Preferences
            </Link>
          </Typography>
        </div>
      )}
      <div className={`profile-section-content${addSmallStyle(isScreenSmall)}`}>
        <div className="profile-section-data">
          <Typography noWrap className="profile-section-data-title">
            Job Position:
          </Typography>
          <Typography
            noWrap
            className="profile-section-data-text"
            title={jobTitle}
          >
            {jobTitle ?? 'N/A'}
          </Typography>
        </div>
        <div className="profile-section-data">
          <Typography noWrap className="profile-section-data-title">
            Email:
          </Typography>
          <Typography
            noWrap
            className="profile-section-data-text"
            title={email}
          >
            {email ?? 'N/A'}
          </Typography>
        </div>
        <div className="profile-section-data">
          <Typography noWrap className="profile-section-data-title">
            Department:
          </Typography>
          <Typography
            noWrap
            className="profile-section-data-text"
            title={department}
          >
            {department ?? 'N/A'}
          </Typography>
        </div>
        <div className="profile-section-data">
          <Typography noWrap className="profile-section-data-title">
            Company Name:
          </Typography>
          <Typography
            noWrap
            className="profile-section-data-text"
            title={config.COMPANY_NAME}
          >
            {config.COMPANY_NAME ?? 'N/A'}
          </Typography>
        </div>
        <div className="profile-section-data">
          <Typography noWrap className="profile-section-data-title">
            Reports to:
          </Typography>
          <div
            className={`profile-section-manager-data${addSmallStyle(
              isScreenSmall,
            )}`}
          >
            <Avatar
              className="profile-section-manager-avatar"
              alt="Manager"
              variant="circular"
              src={managerPhotoUrl ?? defaultAvatar}
            >
              {manager?.substring(0, 1)}
            </Avatar>
            <div className="profile-section-data">
              <Typography
                noWrap
                className="profile-section-data-text"
                title={manager}
              >
                {manager ?? 'N/A'}
              </Typography>
              <Typography noWrap className="profile-section-data-title">
                {managerJobTitle ?? 'N/A'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-section-footer">
        <StyledIconButton
          disableRipple
          onClick={() =>
            setOpenedModal(PROFILE_SECTION_MODALS.USER_GUIDE_MODAL)
          }
          aria-label="Guide"
        >
          <InfoIcon fontSize="inherit" />
          User Guide
        </StyledIconButton>
        <StyledIconButton
          disableRipple
          onClick={() =>
            setOpenedModal(PROFILE_SECTION_MODALS.FEEDBACK_FORM_MODAL)
          }
          aria-label="Feedback"
        >
          <QuestionAnswerIcon fontSize="inherit" />
          Feedback
        </StyledIconButton>
        <StyledIconButton
          disableRipple
          aria-label="Support"
          onClick={handleOnSendEmail}
        >
          <MailIcon fontSize="inherit" />
          Support
        </StyledIconButton>
      </div>

      <BaseModal
        open={openedModal === PROFILE_SECTION_MODALS.USER_GUIDE_MODAL}
        onClose={handleOnCloseModal}
      >
        <UserGuide onClose={handleOnCloseModal} />
      </BaseModal>

      <BaseModal
        open={openedModal === PROFILE_SECTION_MODALS.PROFILE_PHOTO_MODAL}
        onClose={handleOnCloseModal}
      >
        <ProfilePhotoUpload />
      </BaseModal>

      <BaseModal
        open={openedModal === PROFILE_SECTION_MODALS.FEEDBACK_FORM_MODAL}
        onClose={handleOnCloseModal}
      >
        <ContactForm title="Feedback Form" />
      </BaseModal>

      <BaseModal
        open={openedModal === PROFILE_SECTION_MODALS.USER_PREFERENCES_MODAL}
        onClose={handleOnCloseModal}
      >
        <UserPreferences
          preferences={preferences}
          onClose={handleOnCloseModal}
        />
      </BaseModal>
    </div>
  );
}
