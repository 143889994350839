import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  Stack,
  TableFooter,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Typography,
} from '@mui/material';
import { FilterSelect, YearQuarterSelect } from '../../../../common/components';
import CostReportTableRow from './CostReportTableRow';
import DemoRequestsDateRange from '../SubmissionsTab/DemoRequestsTableHeader/DemoRequestsDateRange/DemoRequestsDateRange';
import { COST_REPORT_LABELS, DATE_FORMATS } from '../../../../cms';
import CostReportTableFooter from './CostReportTableFooter';
import { getAllCostReports } from './actions';
import './ReportsTab.scss';

export interface GetAllCostReportRequestModel {
  from: Date | null;
  to: Date | null;
}

export interface CostReportForCompanyData {
  total: number;
  currency: string;
  costReports: CostReport[];
  trainingsCostReport: DetailedCostReport;
  eventsCostReport: DetailedCostReport;
}

export interface CostReport {
  total: number;
  currency: string;
  department: string;
  trainingsCostReport: DetailedCostReport;
  eventsCostReport: DetailedCostReport;
}

export interface DetailedCostReport {
  total: number;
  department: Record<string, number>;
}

export interface StatisticsState {
  costReportForCompanyData: CostReportForCompanyData;
}

export interface RootState {
  statistics: StatisticsState;
}

export default function ReportsTab() {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [quaterButtonName, setQuaterButtonName] = useState<string | null>(null);
  const year = new Date().getFullYear();
  const years = Array.from(new Array(20), (val, index) => year - index);
  const [quaterYear, setQuaterYear] = useState('');
  const { costReportForCompanyData } = useSelector(
    (state: RootState) => state.statistics,
  );

  const handleQuaterYearChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setQuaterYear(event.target.value);
  };

  const onFromDateChange = (newFromDate: string) => {
    if (newFromDate !== undefined)
      setFromDate(dayjs.utc(newFromDate, DATE_FORMATS.SIMPLE_DASH).toDate());
  };

  const onToDateChange = (newToDate: string) => {
    if (newToDate !== undefined)
      setToDate(dayjs.utc(newToDate, DATE_FORMATS.SIMPLE_DASH).toDate());
  };

  const selectQuaterButton = (quaterButton: string) => {
    setQuaterButtonName(quaterButton);
  };

  useEffect(() => {
    const model: GetAllCostReportRequestModel = {
      from: fromDate,
      to: toDate,
    };
    dispatch(getAllCostReports(model));
  }, [fromDate, toDate]);

  useEffect(() => {
    const model: GetAllCostReportRequestModel = {
      from: null,
      to: null,
    };
    if (quaterButtonName === 'Q1') {
      model.from = new Date(`${quaterYear}-01-01`);
      model.to = new Date(`${quaterYear}-03-31`);
    }
    if (quaterButtonName === 'Q2') {
      model.from = new Date(`${quaterYear}-04-01`);
      model.to = new Date(`${quaterYear}-06-30`);
    }
    if (quaterButtonName === 'Q3') {
      model.from = new Date(`${quaterYear}-07-01`);
      model.to = new Date(`${quaterYear}-09-30`);
    }
    if (quaterButtonName === 'Q4') {
      model.from = new Date(`${quaterYear}-10-01`);
      model.to = new Date(`${quaterYear}-12-31`);
    }

    dispatch(getAllCostReports(model));
  }, [quaterButtonName]);

  return (
    <Stack spacing="2rem">
      <h4 className="cost-title">{COST_REPORT_LABELS.COST_REPORT_LABEL}</h4>
      <Stack spacing="2rem" className="cost-request-header">
        <DemoRequestsDateRange
          onFromDateChange={onFromDateChange}
          onToDateChange={onToDateChange}
        />
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" className="cost-report-table">
          <TableHead>
            <TableRow>
              <TableCell className="column" />
              <TableCell className="column cost-report-column">
                {COST_REPORT_LABELS.DEPARTMENT}
              </TableCell>
              <TableCell className="column cost-report-column">
                {COST_REPORT_LABELS.TRAINING_COST}
              </TableCell>
              <TableCell className="column cost-report-column">
                {COST_REPORT_LABELS.EVENT_COST}
              </TableCell>
              <TableCell className="column cost-report-column">
                {COST_REPORT_LABELS.TOTAL_COST}
              </TableCell>
              <TableCell className="action-column" />
            </TableRow>
          </TableHead>
          <TableBody>
            {costReportForCompanyData &&
              costReportForCompanyData.costReports.map((row) => (
                <CostReportTableRow key={row.department} row={row} />
              ))}
          </TableBody>
          <TableFooter>
            <CostReportTableFooter
              trainingsCostReport={
                costReportForCompanyData?.trainingsCostReport
              }
              eventsCostReport={costReportForCompanyData?.eventsCostReport}
              total={costReportForCompanyData?.total}
              currency={costReportForCompanyData?.currency}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Stack>
  );
}
