import {
  ADD_EMPLOYEE_TO_MY_TEAM_URL,
  GET_ALL_EMPLOYEES_URL,
  REMOVE_EMPLOYEE_FROM_MY_TEAM_URL,
  UPDATE_MY_INTERESTS,
} from '../../api/trainingsPortalBackend/config';
import { Employee } from '../../cms/types';
import { api } from './api';

export const employeeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllEmployees: build.query<Employee[], void>({
      query: () => GET_ALL_EMPLOYEES_URL,
      providesTags: ['Employee'],
      forceRefetch: () => true,
    }),
    removeEmployeeFromMyTeam: build.mutation<any, number>({
      query: (id) => ({
        url: `${REMOVE_EMPLOYEE_FROM_MY_TEAM_URL}/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['MyTeamEmployees'],
    }),
    addEmployeesToMyTeam: build.mutation<any, number[]>({
      async queryFn(ids, _queryApi, _extraOptions, fetchWithBQ) {
        const promises = ids.map((id) =>
          fetchWithBQ({
            url: `${ADD_EMPLOYEE_TO_MY_TEAM_URL}/${id}`,
            method: 'PUT',
          }),
        );
        const results = await Promise.all(promises);
        const hasError = results.some((result) => result.error);

        if (hasError) return { error: results };

        return { data: results };
      },
      invalidatesTags: ['MyTeamEmployees'],
    }),
    updateMyInterests: build.mutation<any, number[]>({
      query: (model) => ({
        url: `${UPDATE_MY_INTERESTS}`,
        method: 'PUT',
        body: model,
      }),
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useRemoveEmployeeFromMyTeamMutation,
  useAddEmployeesToMyTeamMutation,
  useUpdateMyInterestsMutation,
} = employeeApi;
